// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // test
  // apiUrl: 'https://eiacapi.mosandah.com.sa',
  // wsUrl: 'wss://eiacapi.mosandah.com.sa:443',

  // dev
  // apiUrl: 'https://eiacdevapi.mosandah.com.sa',
  // wsUrl: 'wss://eiacdevapi.mosandah.com.sa:443',

  //test prod
  apiUrl: 'https://eiactestapi.mosandah.com.sa',
  wsUrl: 'wss://eiactestapi.mosandah.com.sa:443',


  apiUrlTest: 'http://laravel.aioqr.online',
  googleApiKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
  googleSiteKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',

  // test
  // apiUrl: 'https://eiacapi.expertapps.com.sa',
  // apiUrlTest: 'http://laravel.aioqr.online',
  // googleApiKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
  // googleSiteKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
  // wsUrl: 'wss://eiacapi.expertapps.com.sa:443',
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
